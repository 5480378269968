html,
body {
  margin:0;
  padding:0;
}
body {
  height: 100vh;
  width: 100%;
  font-family: _font(family);
  font-weight: 300;
  color: var(--fg);
  letter-spacing: _font(kerning);
  background-color: var(--bg);

}
#main-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  -webkit-transition: filter var(--anim-speed) ease-in;
  -moz-transition: filter var(--anim-speed) ease-in;
  -ms-transition: filter var(--anim-speed) ease-in-out;
  transition: filter var(--anim-speed) ease-in;
  z-index: 1;

}
.content-wrapper {
  max-width: var(--max-width);
  margin: auto;
}
.wrapper {
  margin: 20px 40px;
}
.hero {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  background-color: var(--bg);
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px;
  position: relative;
  min-height: 100vh; 
  width: 100vw;

  .textcontainer {
    background-color: var(--bg-alt);
    border-radius: var(--border-radius);
    max-width: 700px;
    padding: 20px 20px 40px 20px;
  }

  div {
    z-index: 10;
    h1, p {
      flex: 1 0 100%;
      width: 100%;
      z-index: 10;
      color: var(--fg);
      max-width: var(--max-width);
      margin-top: 40px;
    }

    h1 {
      font-size: 1.8em;
    }
  }
  .imagecontainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/vignette.svg');
    background-size: 1024px;
    background-repeat: repeat-x;
    background-position: bottom;
    z-index: 1;
  }
}

section {
  flex: 1 1 100%;
  padding: 80px 20px 80px 20px;
  background-color: var(--bg);
  -webkit-box-shadow: inset 0px 12px 32px -25px var(--border-alt);
  -moz-box-shadow: inset 0px 12px 32px -25px var(--border-alt);
  box-shadow: inset 0px 12px 32px -25px var(--border-alt);
  z-index: 2;
  h2 {
    text-align: center;
  }
}
section:first-of-type {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-top:0;
  padding: 40px 0 0 0;
}
footer {

  width: 100%;
  padding: 160px 20px 200px 20px;
  background-color: var(--fg);
  color: var(--bg);
  background-image: url('../img/vignette-footer.svg');
  background-size: 450px;
  background-repeat: repeat-x;
  background-position: top;
  box-sizing: border-box;
  z-index: 2;
  .footframe {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: var(--max-width);
    margin: auto;
    padding: 20px;
  
    div {
      flex: 0 1 300px;
      margin-bottom: 80px;
      img {
        margin: auto;
        display: block;
        width: 100%;
      }
      li {
        border-bottom: 1px solid var(--bg-alt);
        line-height: 2;
        font-size: 1.5em;
        a {
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  
    small {
      flex: 0 0 100%;
      box-sizing: border-box;
      margin: 0;
      text-align: center;
      font-size: 0.8em;
      .label {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 679px) {
    .footframe {
      justify-content: center;
      div {
        flex-grow: 1;
      }
    }
  }
}

.post-navigation {
  padding: 80px 0 160px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  h2 {
    flex: 0 0 100%;
    margin-top: 40px;
    text-align: center;
  }
  .post-nav, .post-nav-next {

    flex: 0 0 200px;
    margin: 40px;
    h4 {
      font-size: 2em;
      justify-self: right;
    }
  }

}