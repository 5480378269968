
// Basic Elements

h1, h2, h3, h4, h5, h6 {
  font-family: _font(title);

}
h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 1.75em;
}
h3 {
  font-size: 1.5em;
}
strong {
  font-weight: 500;
}
a {
  text-decoration-style: wavy;
  text-decoration-color: transparent;
  color: var(--accent2);
  -moz-transition: all var(--anim-speed) ease-in-out, color var(--anim-speed) ease-in-out;
  -webkit-transition: all var(--anim-speed) ease-in-out, color var(--anim-speed) ease-in-out;
  -ms-transition: all var(--anim-speed) ease-in-out, color var(--anim-speed) ease-in-out;
  transition: all var(--anim-speed) ease-in-out, color var(--anim-speed) ease-in-out;
}
a:hover {
  color: var(--accent1);
  text-decoration-style: wavy;
  text-decoration-color: unset;
}

// Buttons

.button {
  background-color: var(--accent2);
  padding: 1em;
  display: inline-block;
  border-radius: var(--border-radius);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--bg);
  -webkit-box-shadow: 0px 0px 0px -3px var(--border-alt);
  -moz-box-shadow: 0px 0px 0px -3px var(--border-alt);
  box-shadow: 0px 0px 0px -3px var(--border-alt);
  -webkit-transition: all var(--anim-speed) ease-in-out;
  -moz-transition: all var(--anim-speed) ease-in-out;
  -ms-transition: all var(--anim-speed) ease-in-out;
  transition: all var(--anim-speed) ease-in-out;


  
}
.button:hover {
  background-color: var(--accent1);
  color: var(--bg);
  -webkit-box-shadow: 0px 11px 10px -3px var(--border-alt);
  -moz-box-shadow: 0px 11px 10px -3px var(--border-alt);
  box-shadow: 0px 11px 10px -3px var(--border-alt);
  -webkit-transition: all var(--anim-speed) ease-in-out;
  -moz-transition: all var(--anim-speed) ease-in-out;
  -ms-transition: all var(--anim-speed) ease-in-out;
  transition: all var(--anim-speed) ease-in-out;
}
.button:active {
  -webkit-box-shadow: 0px 0px 0px -3px var(--border-alt);
  -moz-box-shadow: 0px 0px 0px -3px var(--border-alt);
  box-shadow: 0px 0px 0px -3px var(--border-alt);
}

.normal-section {
  display:flex; 
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 40px;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset;
  max-width: var(--max-width);
  margin: auto;
  h2 {
    flex: 0 0 100%;
    margin: 20px;
  }

    p {
      margin-top: 20px;
    }
    ul{
      width: 100%;

    }
    .streamer {
      margin-top:0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    
    }
    .icon {
      flex: 0 0 100px;
      margin: 0 0 20px 0;
      display: inline-block;
      width: 100px;
      height: 100px;
      border-radius: 200px;
      font-size: 40px;

      line-height: 100px;
      text-align: center;

    }


  
  img {
    width:100%;
    height: 100%; 
    object-fit: cover;
    border-radius: var(--border-radius);
  }
}

// Streambox
.streambox {
  text-align: center;
  line-height: 2em;
  > h2, div {
    margin: 20px;
  }
}
.streamer {
  margin-top: 2em;
  text-align: center;

}
// icon

.label {
  display: none;
}
.icon {
  flex: 0 0 45px;
  margin:20px; 
  font-size: 45px;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border: 1px solid var(--accent2);
  text-align: center;
  text-decoration: none;
  -moz-transition: border var(--anim-speed) ease-in-out, color var(--anim-speed) ease-in-out;
  -webkit-transition: border var(--anim-speed) ease-in-out, color var(--anim-speed) ease-in-out;
  -ms-transition: border var(--anim-speed) ease-in-out, color var(--anim-speed) ease-in-out;
  transition: border var(--anim-speed) ease-in-out, color var(--anim-speed) ease-in-out;
  display: inline-block;

}
.icon:hover {
  border: 1px solid var(--accent1);

}
.small {
  border-radius: 80px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 45px;
  margin-left: 0;
}
.smaller {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  margin: 10px 10px 10px 0;
}

// Content Author
#author {
  flex: 0 0 280px;
  margin: 20px;
  .date {
    font-size:.85em;
  }
  .author-image {
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size:cover; 
    border-radius:150px; 
    width:150px; 
    height:150px;
  }
}

.info{
  padding: 40px;

  margin: auto;
  .info-pages {
    padding: 40px;
    max-width: var(--max-width);
    margin: auto;
  }
  p, ul {
    margin: 20px 0;
  }
  ul {
    list-style-type: circle;
    list-style-position: inside;
  }
  h2, h3, h4, h5, h6 {
    text-align: left;
  }
}

.news, .links {
  margin-top: 40px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  h2 {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  > div {
    background-size: cover;
    background-repeat: no;
    background-position: center center;
    flex: 1 1 512px;
    min-height: 80vh;

    padding: 40px;
    box-sizing: border-box;
    text-align: left;

  }
  .description {
    h2, p, a {
      text-align: center;
    }
    p {
      margin-top: 20px;
    }
    p:last-child {
      margin-bottom: 40px;
    }
    min-height: unset;
  }
}
.news:nth-child(even), .links:nth-child(even) {
  flex-direction: row-reverse;

}
.links {
  > div {
    min-height: unset;
  }
 
}
.linksbg {
  background-size: 100%;
  background: var(--bg);
  background: linear-gradient(180deg, var(--bg) 26%, var(--accent2-alt) 100%);

}


.front-section {
  flex-direction: row-reverse;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  p, h1, h2, h3, h4 {
    text-align: left;
    margin-top: 20px;
    max-width: 570px;
  }
  p:last-child {
    margin-bottom: 40px;
  }

  > div {
    background-size: cover;
    background-repeat: no;
    background-position: center center;
    flex: 1 1 380px;
    min-height: 50vh;
    
    padding: 20px;
    box-sizing: border-box;
    text-align: left;

  }
  .description {
    min-height: unset;
  }
  .container {
    max-width: 460px;
    margin: 0 20px 0 auto;
  }
  @media only screen and (max-width: 1080px) {
  
    .container {
       margin: 0 20px 0 20px;
    }
  }
}
.front-section:nth-child(even) {
  flex-direction: row;
  .container {

    margin: 0 auto 0 20px;
  }
  @media only screen and (max-width: 1000px) {
    .container {

      margin: 0 20px 0 20px;
    }
  }

}



.splash {
  display: flex;
  flex-wrap: wrap;

  align-items: flex-start;
  max-width: var(--max-width);

  background-color: transparent;

  div {
    max-width: unset;
    margin: 0;
    flex: 1 1 300px;
    h1, h2 {
      font-size: 1.5em;
    }



  }
  .splash-logo {
    padding: 0;
    margin: 0;
  }
  @media only screen and (max-width: 759px) {
    div {


    }
    .splash-logo {
      padding: 20px 0;
    }
  }
}

#contact {
  padding: 20px;

  > div {
    max-width: var(--max-width);
    margin: auto;
    padding: 40px 20px;
  }
  h2 {
    padding-top: 20px;
  }
}

